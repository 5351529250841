<template>
  <v-container class="pt-7 pb-11">
    <v-progress-linear v-if="getLoading" height="2" indeterminate/>
    <template v-else>
      <v-row class="mt-0 mb-4">
        <v-col>
          <v-breadcrumbs :items="breadcrumbsItems" divider="/" class="pa-0"/>
        </v-col>
      </v-row>
      <v-row class="mb-8" justify="space-between" align="center">
        <v-col cols="auto" class="text-left">
          <h1 class="text-h4 mb-0">{{ 'myAccount'|localize }}</h1>
        </v-col>
      </v-row>
      <div class="white elevation-2 rounded">
        <v-container class="px-4">
          <v-form>
            <v-row class="mb-2" align="center" justify="space-between">
              <v-col cols="6">
                <h6 class="text-subtitle-1 mb-2">{{ 'name' | localize }}</h6>
                <template v-if="name">{{ name }}</template>
                <template v-else>--</template>
              </v-col>
              <v-col cols="6">
                <h6 class="text-subtitle-1 mb-2">{{ 'email' | localize }}</h6>
                <template v-if="email">{{ email }}</template>
                <template v-else>--</template>
              </v-col>
            </v-row>
            <v-row class="mb-2" align="center" justify="space-between">
              <v-col cols="6">
                <v-checkbox v-model="isNotify" hide-details class="mt-0 mb-2">
                  <template v-slot:label>
                    <span class="black--text">{{ 'notifyEmails' | localize }}</span>
                  </template>
                </v-checkbox>
              </v-col>
              <v-col cols="6">
                <h6 class="text-subtitle-1 mb-2">ID</h6>
                <template>{{ custno }}</template>
              </v-col>
            </v-row>
            <v-row class="mb-2" align="center" justify="space-between">
              <v-col cols="6">
                <v-btn
                  rounded
                  depressed
                  color="primary"
                  :loading="saveLoading"
                  :disabled="saveLoading"
                  @click="updateUserSettings"
                > {{ 'save'|localize }}
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </div>
    </template>
  </v-container>
</template>

<script>
  import api from '@/api/api';
  import methods from '@/helpers/methods';
  import localize from '@/filters/localize';

  export default {
    name: "Account",

    data() {
      return {
        name: '',
        email: '',
        custno: null,
        isNotify: true,
        getLoading: false,
        saveLoading: false
      };
    },
    async mounted() {
      try {
        this.getLoading = true;
        let userData = await api.getUser();
        this.setUserProfile(userData.data)
        this.getLoading = false;
      } catch (e) {
        this.getLoading = false;
      }
    },
    computed: {
      breadcrumbsItems() {
        return [
          {
            text: localize('myQuotes'),
            disabled: false,
            to: {name: 'customer-requests'}
          },
          {
            text: localize('myAccount'),
            disabled: true,
          },
        ]
      },
    },
    methods: {
      setUserProfile(userData) {
        this.name = userData.name;
        this.email = userData.email;
        this.custno = userData.custno;
        this.isNotify = userData.send_notification;
      },
      async updateUserSettings() {
        try {
          this.saveLoading = true;
          await api.updateUserSettings({send_notification: this.isNotify})
          methods.showGlobalSnackbar('success', localize('done'));
          this.saveLoading = false;
        } catch (e) {
          this.saveLoading = false;
          methods.showGlobalSnackbar('error', methods.getErrors(e.response));
        }
      },
    }
  };
</script>
